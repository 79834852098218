import { render, staticRenderFns } from "./Vente.vue?vue&type=template&id=60f756d8&scoped=true&"
import script from "./Vente.vue?vue&type=script&lang=js&"
export * from "./Vente.vue?vue&type=script&lang=js&"
import style0 from "./Vente.vue?vue&type=style&index=0&id=60f756d8&lang=scss&scoped=true&"
import style1 from "./Vente.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60f756d8",
  null
  
)

export default component.exports